<template>
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card ">
		<h5>Filter</h5>
		<hr>
		<div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar :manualInput="false" v-model="period" view="month" :yearNavigator="true" dateFormat="MM yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
			<div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Distributor</label>
                    <MultiSelect id="dist_code" dataKey="dist_code" v-model="dist_codes" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor"
                        optionLabel="label" placeholder="Pilih Distributor" :filter="true" :showToggleAll="false"
                        :showClear="true" @filter="searchDropdownDistributor($event, 'filter')"/>
                </div>
			</div>
		</div>
        <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter"/>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card">
				<h4>REGISTER OUTLET BY DISTRIBUTOR</h4>
				<RegisOutletByDistDataTable :filter="filters" :period="period_label"/>
			</div>
        </div>
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card ">
				<h4>AKTIF OUTLET BY DISTRIBUTOR</h4>
				<AktifOutletByDistDataTable :filter="filters" :period="period_label"/>
			</div>
        </div>
	</div>

	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card">
				<h4>EFFECTIVE CALL BY DISTRIBUTOR</h4>
				<EffectiveCallByDistDataTable :filter="filters" :period="period_label"/>
			</div>
        </div>
	</div>

	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
				<h4>MAPS - OUTLET</h4>
				<OutletMap :filter="filters" :period="period_label"/>
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import Error from '../components/Error.vue';
import RegisOutletByDistDataTable from '../components/dashboard/RegisOutletByDistDataTable.vue';
import AktifOutletByDistDataTable from '../components/dashboard/AktifOutletByDistDataTable.vue';
import EffectiveCallByDistDataTable from '../components/dashboard/EffectiveCallByDistDataTable.vue';
import OutletMap from '../components/dashboard/OutletMap.vue';

export default {
	components: {
		'Error': Error,
		'RegisOutletByDistDataTable': RegisOutletByDistDataTable,
		'AktifOutletByDistDataTable': AktifOutletByDistDataTable,
		'EffectiveCallByDistDataTable': EffectiveCallByDistDataTable,
		'OutletMap': OutletMap,
	},
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,

            // loading
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownDistributor: null,

            // filter
            filters: {
                dist_code: null,
            },
            dist_codes: null,

            period: new Date(),
		}
	},
    computed:{
        ...mapGetters(['errors']),
        period_label(){ 
            return moment(this.period, 'YYYY-MM').format('YYYY-MM'); 
        },
    },
    watch: {
        dist_codes() {
            this.filters.dist_code = this.dist_codes ? this.convertMultiSelectDistributor(this.dist_codes) : null;
        },
    },
    mounted() {
        this.searchDropdownDistributor('');
    },
	methods: {
        convertMultiSelectDistributor(data) {
            if(data){
                return data.map((item) => {
                    return item.dist_code;
                });
            }
        },
        clearFilter(){
            this.filters.dist_code = null;
            this.dist_codes = null;
            this.period= new Date();
        },
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;

                            //Multiple Selected
                            if(this.dist_codes){
                                this.dataDropdownDistributor.push(...this.dist_codes);

                                const uniqueids= [];
                                this.dataDropdownDistributor = this.dataDropdownDistributor.filter((c) => {
                                    const isDup = uniqueids.includes(c.dist_code);
                                    if(!isDup){
                                        uniqueids.push(c.dist_code);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
	}
}
</script>