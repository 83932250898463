<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Jenis Outlet</label>
                <Dropdown v-model="filters.jenis_outlet" :options="dataDropdownJenisOutlet"
                    optionLabel="name" optionValue="code" placeholder="Pilih Jenis Outlet" :filter="true" :showClear="true"/>
            </div>
        </div>
    </div>
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
        <div>
            <Button type="button" :label="'Total '+this.total_visit" icon="pi pi-users" class="p-button-primary"/>
        </div>
        <span class="block mt-2 md:mt-0 p-input-icon-left">
            <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
        </span>
    </div>
        <BlockUI :blocked="loading">
        <div class="map" id="outlet_map"></div>
    </BlockUI>
</template>

<script>

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup();

export default {
    props: [
		"filter",
        "period"
    ],
    data() {
        return {

            // dataDropdown
            dataDropdownJenisOutlet: [
                {name: 'NOO', code: 'NOO'},
                {name: 'REGISTER OUTLET', code: 'REGISTER'},
            ],

            // filter
            filters: {
                jenis_outlet: null,
            },


            loading: false,
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_visit: 0
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = L.map('outlet_map', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

        // this.showLegend();
    },
    created(){
        this.showMarker();
    },
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        },
        filters: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        },
        period: {
            handler() {
                setTimeout(() => {
                    this.showMarker();
                }, 500);
            },
        }
    },
    methods:{
        showMarker(){

            this.loading= true;

            markers.clearLayers();
            
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/outlet-maps',
                params: {
                    "distributor": this.filter.dist_code,
                    "jenis_outlet": this.filters.jenis_outlet,
                    "periode": this.period,
                }
            })
            .then(res => {
                this.location_list = res.data.data;
                this.total_visit = res.data.data.length;

                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.outlet_lat != null && value.outlet_lng != null){

                        var htmlcontent = "<b>Kode Outlet</b> : " + value.cust_code + "</b><br/>";
                            htmlcontent += "<b>Nama Outlet</b> : " + value.cust_name + "<br/>";
                            htmlcontent += "<b>Wilayah</b> : " + value.wilayah_name + "<br/>";
                            htmlcontent += "<b>Klasifikasi</b> : " + value.klasifikasi_name + "<br/>";
                            htmlcontent += "<b>Kode Distributor</b> : " + value.dist_code + "<br/>";
                            htmlcontent += "<b>Nama Distributor</b> : " + value.dist_name + "<br/>";

                        var iconUrl = '';

                        if (value.status_visit == "visit") {
                            iconUrl = "/marker/marker-type-outlet-lainnya-beat.png";
                        } else if(value.status_visit == "ec"){
                            iconUrl = "/marker/marker-ec.png";
                        }else {
                            iconUrl = "/marker/marker-type-outlet-lainnya.png";
                        }
                        
                        // eslint-disable-next-line no-undef, no-unused-vars
                        var icon = L.icon({
                            iconUrl: iconUrl,
                            outlet_id: value.outlet_id,
                            iconSize: [21, 36], // size of the icon
                            // shadowSize:   [50, 64], // size of the shadow
                            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62], // the same for the shadow
                            popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                        })


                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_lat, value.outlet_lng],
                            {
                                // icon: icon,
                            });



                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                    }
                }); 
                
                this.map.addLayer(markers);

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        // showLegend() {
        //     setTimeout(() => {
        //         // eslint-disable-next-line no-undef
        //         var legend = L.control({ position: "bottomleft" });

        //         legend.onAdd = function () {

        //             // eslint-disable-next-line no-undef
        //             var div = L.DomUtil.create("div", "legend");
        //             div.innerHTML += "<h4>Keterangan</h4>";
        //             div.innerHTML += '<i style="background: red"></i><span>Visit</span><br>';
        //             div.innerHTML += '<i style="background: black"></i><span>Not Visit</span><br>';
        //             div.innerHTML += '<i style="background: green"></i><span>EC</span><br>';

        //             return div;
        //         };

        //         legend.addTo(this.map);
        //     }, 250);
        // },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
